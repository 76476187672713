<template>
  <section class="role-dashboard-view">


    <el-row type="flex" align="middle">
      <el-col :span="12" :xs="10" :sm="10">
        <h2 class="h2 f-weight-350 mt-0 d-flex align-center">
          <el-button size="mini" icon="el-icon-back" class="default-button btn button-left" @click="goBack()">Back
          </el-button>

        </h2>

      </el-col>
    </el-row>

    <el-row>
      <el-col :span="22" :xs="14" :sm="14">
        <h2 class="h2 f-weight-350 mt-0 d-flex align-center ">
          <img class="" src="@/assets/img/icons/menu/dashboard.svg" alt="menu" width="30" />
          <span class="text pl-1"> Role Management </span>
        </h2>
      </el-col>
      <el-col :span="2" :xs="10" :sm="10">
        <el-button class="float-right w-150 " type="primary" size="medium" @click="onAddRole" plain>
          <span class="el-icon-circle-plus"></span> Add Role
        </el-button>
      </el-col>
    </el-row>

    <div class="inner-navbar">

      <div class="d-flex align-center space-between mb-10">

        <el-breadcrumb separator-class="el-icon-arrow-right">

          <el-breadcrumb-item :to="{ path: '/' }">Dashboard</el-breadcrumb-item>

          <el-breadcrumb-item>Roles</el-breadcrumb-item>

        </el-breadcrumb>

      </div>

    </div>

    <div class="inner-navbar-content top" v-if="!loading && allRoles.length">

      <el-row :gutter="15">
        <el-col :span="24" :xs="24" :sm="12" :lg="6" v-for="(role, index) of allRoles" :key="index">
          <el-card class="box-card" shadow="hover" style="margin: 5px; padding: 5px">

            <div slot="header" class="clearfix">

              <span class="on-over ">
                <!-- {{ role.title| truncate(15, "...") }} -->
                <span :title="role.title.length > 15 ? role.title : ''">{{ role.title | truncate(15, "...") }}</span> 
              </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <template v-if="!role.isSuper">
                <i class="el-icon-edit on-over" @click="onEditRole(role)"></i> &nbsp;&nbsp;&nbsp;

                <i class="el-icon-delete on-over" @click="onDeleteRole(role)"></i>
              </template>



            </div>

            <!-- {{role}} -->

            <div v-if="role.allowed_groups.length">
              <el-tag :key="tag" v-for="tag in role.allowed_groups" :disable-transitions="false" :type="getType">

                {{ getGroupName(tag) }}

              </el-tag>
            </div>

          </el-card>

        </el-col>

      </el-row>



      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize"
        layout="total, prev, pager, next, jumper" :total="totalRoles">

      </el-pagination>

    </div>

    <div class="inner-navbar-content top" v-else>

      <el-empty description="No Roles" v-if="!loading"></el-empty>

      <template v-else>

        <el-row>

          <el-col :span="24" :xs="24" :sm="12" :lg="6" v-for="o in 8" :key="o">

            <el-card style="margin: 5px; padding: 5px">

              <el-skeleton animated>

                <template slot="template">

                  <el-skeleton-item variant="p" style="width: 50%" />

                  <el-divider class="mt-0 mb-0"></el-divider>

                  <el-skeleton-item />

                  <div style="padding: 14px">

                    <div style="
                                                                          display: flex;
                                                                          align-items: center;
                                                                          justify-items: space-between;">

                      <el-skeleton-item variant="text" style="margin-right: 16px" />

                      <el-skeleton-item variant="text" style="width: 30%" />

                    </div>

                  </div>
                </template>
              </el-skeleton>
            </el-card>
          </el-col>
        </el-row>
      </template>
    </div>
    <AddEditRole v-if="showAddEditRole" @close="closeAddEditRole" :existedRole="selectedRole">
    </AddEditRole>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import AddEditRole from "./AddEditRole";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
export default {
  components: {
    AddEditRole,
  },
  data() {
    return {
      loading: false,
      allRoles: [],
      totalRoles: 0,
      showAddEditRole: false,
      selectedRole: {},
      currentPage: 1,
      pageSize: 12,
      allEntitiesGroups: [],
      types: ["success", "info", "warning", "danger"]
    };
  },
  computed: {
    ...mapGetters("roles", ["getAllRoles", "getSuccess", "getRoleErrors"]),
    ...mapGetters("entityGroups", ["getAllEntityGroupsData"]),
    getType() {
      let types = ["success", "info", "warning", "danger"];
      const index = Math.floor(Math.random() * types.length)
      return types[index]
    },

  },
  mounted() {
    this.getCompanyInformation();
    this.fetchRoles(1);
    this.fetAllEntityGroups();
  },
  mixins: [DocumentsHelper],
  methods: {
    goBack() {
      this.$router.push({
        path: "/profile",
      });
    },

    onDeleteRole(role) {
      this.$confirm(
        `Are you sure to delete the ${role.title} ?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteRole(role);
      });
    },
    async deleteRole(role) {
      this.loading = true;
      try {
        await this.$store.dispatch("roles/deleteRole",
          role._id,
        );
        if (this.getSuccess && this.getSuccess.success) {
          this.$notify.success({
            title: "Success",
            message: `${role.title} deleted successfully`,
          });
          this.fetchRoles(1);
        } else {
          throw this.getRoleErrors;
        }
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message:
            this.getRoleErrors.message ||
            "Sorry! This Role is associated with Users",
        });
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },

    getGroupName(id) {
      // let roleWithUserType = this.lodash.filter(
      //   this.allEntitiesGroups,
      //   function (element) {
      //     return element._id === id;
      //   }
      // );
      let roleWithUserType = this.allEntitiesGroups.filter(element => element._id === id)
      if (roleWithUserType && roleWithUserType.length) {
        return roleWithUserType[0].name
      } else {
        return 'E-SIGNS'
      }
    },
    async fetAllEntityGroups() {
      this.loading = true;
      let params = {
        get_all: true,
      };
      await this.$store.dispatch("entityGroups/fetEntityGroupsData", params);
      this.allEntitiesGroups = this.getAllEntityGroupsData ?
        this.getAllEntityGroupsData.data :
        [];
      this.loading = false;
    },
    handleCurrentChange(page) {
      this.fetchRoles(page);
    },

    async fetchRoles(page) {
      this.loading = true;
      let params = {
        page: page,
        limit: this.pageSize,
        order_by: "name",
        order_type: "asc",
        skip: 0,
      };
      await this.$store.dispatch("roles/fetchRoles", params);
      this.allRoles = this.getAllRoles ? this.getAllRoles.data : [];
      this.totalRoles = this.getAllRoles.total;
      this.currentPage = parseInt(this.getAllRoles.page);
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    onAddRole() {
      this.showAddEditRole = true;
      this.selectedRole = {};
    },
    onEditRole(role) {
      this.selectedRole = role;
      this.showAddEditRole = true;
    },

    async closeAddEditRole(status) {
      this.showAddEditRole = false;
      if (status) {
        await this.fetchRoles(1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.role-dashboard-view {
  width: 90%;
  display: table;
  margin: 0 auto;

  .add-button {
    padding: 0.45em 0.65em;
    color: rgb(81, 161, 0);

    &:hover {
      background-color: rgb(81, 161, 0);
      color: #ffffff;
      border-color: rgb(81, 161, 0);
    }
  }

  .inner-navbar-content {
    margin-top: 20px;
  }

  .role-box {
    margin-bottom: 15px;

    .role-title {
      height: 45px;
      font-size: 1.1em;
    }

    .actions-btn {
      .each-btn {
        padding: 2px 5px;
        margin-left: 8px;
        border: 1px solid #efefef;
        border-radius: 3px;

        &:hover {
          background-color: #ffffff;
          border: 1px solid #dddddd;
        }

        i {
          font-size: 1.1em;
        }
      }
    }

    .entities-count {
      .count {
        font-size: 1.1em;
        line-height: 1;
      }
    }
  }

  .on-over:hover {
    cursor: pointer;
  }

  .font-semi-bold {
    font-weight: 400;
  }

  .el-tag+.el-tag {
    margin: 0.2rem;


  }
}
.el-button--primary.is-plain {
  color: var(--primary-color);
  background: var(--primary-color-text);
  border-color: var(--lighter-primary-color);
}
.el-button--primary.is-plain:hover {
  color: var(--primary-color-text);
  background: var(--primary-color);
  border-color: var(--lighter-primary-color);
}
</style>

<style lang="scss"></style>